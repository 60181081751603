import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/layout";

export default function TermsConditions() {
  return (
    <Layout>
      <Container className="body-margin">
        <div className="card">
          <div className="image"></div>
          <div className="content">
            <div className="text-center mb-3">
              <h1>Terms &amp; Conditions</h1>
            </div>
            <div>
              <article>
                <aside>
                  This website provides information about funds managed by Fjord
                  Fund Management Limited. The funds are alternative investment
                  funds for the purposes of the UK's Alternative Investment Fund
                  Managers Regulations 2013 (the AIFM Regulations). Accordingly,
                  they can be marketed into the United Kingdom only if Fjord
                  Fund Management Limited has notified the UK Financial Conduct
                  Authority (FCA) in accordance with Article 59 of the AIFM
                  Regulations that it intends to market the funds in the UK.
                  Further information about the funds will be available only in
                  respect of those funds in relation to which the notification
                  has been made or otherwise where the information can lawfully
                  be provided.
                  <br />
                  <br />
                  The funds are unregulated collective investment schemes
                  ("UCIS") for the purposes of the UK's Financial Services and
                  Markets Act 2000 ("FSMA") and the promotion of a UCIS either
                  within the UK or from the UK is restricted by FSMA. As such,
                  this website and its contents are directed only at persons who
                  are Professional Clients or Eligible Counterparties as defined
                  by the rules of the UK Financial Conduct Authority. A
                  commitment to the funds should only be made by persons with
                  professional experience of participating in such funds.
                  <br />
                  <br />
                  The information contained and accessed on this site is
                  provided by Fjord Advisors Limited for general guidance and is
                  intended to offer the user general information of interest.
                  Accordingly, the information provided is not intended to
                  replace or serve as substitute for any investment, consulting,
                  legal or other professional advice or service. Before making
                  any decision or taking any action which might affect your
                  personal finances or business, you should consult a qualified
                  professional advisor.
                  <br />
                  <br />
                  Due to the nature of electronic communication processes, Fjord
                  Advisors Limited does not guarantee or warrant the site will
                  be uninterrupted, without delay, error-free, omission-free, or
                  free of viruses. Therefore, the information is provided 'as
                  is' without warranties of any kind, express or implied,
                  including accuracy, timeliness and completeness. Fjord
                  Advisors Limited reserves the right to make changes to the
                  site and its contents at any time and without notice.
                  <br />
                  <br />
                  In no event shall Fjord Advisors Limited, or any of its
                  partners, agents or employees be liable for any special,
                  indirect, incidental, consequential or punitive damages or any
                  other damages whatsoever, whether in an action of contract,
                  statute, tort (including, without limitation, negligence) or
                  otherwise, relating to the use of this site or the information
                  contained in it.
                  <br />
                  <br />
                  As content on the site is copyrighted and proprietary, any
                  unauthorized use of any materials on the site may violate
                  copyright, trademark and other laws. Should a user download
                  the materials on the site for personal or non-commercial use,
                  the user must retain all copyright, trademark or other similar
                  notices contained in the original materials or on any copies
                  of the material. Materials on the site may not be modified,
                  reproduced, publicly displayed, performed, distributed or used
                  for any public or commercial purposes without explicit written
                  permission from the appropriate content or material provider
                  (including third-party links). Fjord Advisors Limited bears no
                  risk, responsibility or liability in the event that a user
                  does not obtain such explicit written permission as advised.
                  <br />
                  <br />
                  Third-party links are provided as a convenience to our users.
                  Fjord Advisors Limited does not control and is not responsible
                  for any of these sites or their content.
                  <br />
                  <br />
                  Fjord Advisors Limited. Authorised and regulated by the UK
                  Financial Conduct Authority with number 538629. Registered in
                  England and Wales with registration number 07472781.
                  Registered office: Kennett House Broad Street, East Ilsley,
                  Newbury, RG20 7LW, United Kingdom.
                  <br />
                  <br />
                  Fjord Fund Management Limited. Licensed by the Isle of Man
                  Financial Services Authority. Registered in the Isle of Man
                  with registration number 002756V. Registered office:
                  Clinch&rsquo;s House, Lord Street, Douglas, Isle of Man, IM99
                  1RZ.
                </aside>
              </article>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
